let PushnewsServiceWorker = {
  SDK_VERSION: 20600,

  appId: null,
  subscriberId: null,
  baseApiUrl: 'https://api.pn.vg/api/v1/',

  /**
   * Util: get URL from parameter
   *
   * @param param
   * @returns {string|null}
   */
  getUrlParam: function(param) {
    const paramsString = self.location.search;
    const searchParams = new URLSearchParams(paramsString);
    let paramValue = searchParams.get(param);

    return null !== paramValue ? decodeURIComponent(paramValue) : null;
  },

  /**
   * Util: process all URL parameters and if there's a property
   * with the same name on PushnewsServiceWorker, set it's value
   */
  processUrlParams: function() {
    const paramsString = self.location.search;
    const searchParams = new URLSearchParams(paramsString);
    for (let [key, value] of searchParams) {
      if (this.hasOwnProperty(key)) {
        value = decodeURIComponent(value);
        this[key] = value;
      }
    }
  },

  /**
   * Updates the subscribers "identifier" on the API
   *
   * @param oldSubscription
   * @param subscription
   * @returns {Promise<void>}
   */
  updateSubscriber: async function (oldSubscription, subscription) {
    if (!this.appId) {
      return;
    }

    try {
      const response = await fetch(this.baseApiUrl + 'update-player-identifier', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          appId: this.appId,
          oldIdentifier: oldSubscription.endpoint,

          identifier: subscription.endpoint,
          webAuth: subscription.keys.auth,
          webP256: subscription.keys.p256dh
        }),
      });

      if (!response.ok) {
        console.error('[PN-SDK-ServiceWorker] Bad status code from server.');
        return;
      }
      const responseData = await response.json();
      console.log('[PN-SDK-ServiceWorker] ### response data', responseData);
      if (!(responseData && responseData.success)) {
        console.error('[PN-SDK-ServiceWorker] Bad response from server.');
      }
    } catch (err) {
      console.error('[PN-SDK-ServiceWorker] * Could not update subscriber', err);
    }
  },

  /**
   * Sends a request to the API to count a new display on the given Notification
   *
   * @param notificationId
   * @returns {Promise<void>}
   */
  trackDisplay: async function (notificationId) {
    if (!this.appId || !notificationId || "undefined" === typeof notificationId) {
      console.error('[PN-SDK-ServiceWorker] Missing appId or notificationId');
      return;
    }

    await fetch(this.baseApiUrl + 'notifications-display/' + notificationId, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appId: this.appId
      }),
    });
  },

  /**
   * Sends a request to the API to count a new click on welcome and goodbye push
   *
   * @param trackData
   * @returns {Promise<void>}
   */
  trackClick: async function (trackData) {
    const { appId, hash, pushType } = trackData;
    if (!appId || !hash || !pushType) {
      console.error('[PN-SDK-ServiceWorker] Missing appId, hash or pushType');
      return;
    }

    await fetch(this.baseApiUrl + 'appfields/track-click', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appId: appId,
        value: hash,
        type: pushType
      }),
    });
  },

  /**
   * Attach event listeners
   */
  attachEventListeners: function() {

    self.addEventListener('ready', (event) => {
      console.log('[PN-SDK-ServiceWorker] ready', event);
      console.log('[PN-SDK-ServiceWorker] appId', PushnewsServiceWorker.getUrlParam('appId'));
    });

    self.addEventListener('install', (event) => {
      // At this point, the old service worker is still in control
      event.waitUntil(self.skipWaiting());
    });

    self.addEventListener('activate', (event) => {
      // Enable the waiting service worker to immediately become the active service worker: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerGlobalScope/skipWaiting
      // -> The old service worker is gone now
      event.waitUntil(self.clients.claim());
    });

    self.addEventListener('message', (event) => {
      console.log('[PN-SDK-ServiceWorker] message', event);
      console.log('[PN-SDK-ServiceWorker] appId', PushnewsServiceWorker.getUrlParam('appId'));
    });

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerGlobalScope/pushsubscriptionchange_event
     */
    self.addEventListener('pushsubscriptionchange', (event) => {
      console.log('[PN-SDK-ServiceWorker] pushsubscriptionchange', event);
      const subscription = registration.pushManager
          .subscribe(event.oldSubscription.options)
          .then(subscription => {
                this.updateSubscriber(event.oldSubscription, subscription);
              }
          );

      event.waitUntil(subscription);
    });

    self.addEventListener('push', (event) => {
      console.log('[PN-SDK-ServiceWorker] push', event);
      if (event.data) {
        console.log('[PN-SDK-ServiceWorker] This push event has data: ', event.data.text());
      } else {
        console.log('[PN-SDK-ServiceWorker] This push event has no data.');
      }
      let data = event.data.json();
      let title = data.title;
      let options = {
        body: data.alert,
        icon: data.icon,
        image: data.image,

        /**
         * Tags are any string value that groups notifications together. Two
         * or notifications sharing a tag replace each other.
         */
        tag: data.tag,

        /**
         * On Chrome 50+, by default notifications replacing
         * identically-tagged notifications no longer vibrate/signal the user
         * that a new notification has come in. This flag allows subsequent
         * notifications to re-alert the user. See:
         * https://developers.google.com/web/updates/2016/03/notifications
         */
        renotify: true,

        /**
         * On Chrome 47+ (desktop), notifications will be dismissed after 20
         * seconds unless requireInteraction is set to true. See:
         * https://developers.google.com/web/updates/2015/10/notification-requireInteractiom
         * https://developer.mozilla.org/en-US/docs/Web/API/Notification/requireInteraction
         */
        requireInteraction: true,

        data: {
          id: data.custom.i,
          url: data.custom.u,
        },
      };
      if (data.o) {
        let webButtons = [];
        for (let index = 0; index < data.o.length; index++) {
          const element = data.o[index];
          webButtons.push({
            action: element.u,
            title: element.n,
            icon: element.p,
          });
        }
        options.actions = webButtons;
      }

      let trackDisplay = data.custom.hasOwnProperty('t') && data.custom.t;

      const promiseChain = self.registration.showNotification(title, options).then(() => {
        if (trackDisplay){
          PushnewsServiceWorker.trackDisplay(data.custom.i).then(() => { });
        } else {
          return Promise.resolve();
        }
      });

      event.waitUntil(promiseChain);
    });

    self.addEventListener('notificationclick', (event) => {
      console.log('[PN-SDK-ServiceWorker] notificationclick', event);
      const clickedNotification = event.notification;
      clickedNotification.close();

      let url = '';
      if (!event.action) {
        // Was a normal notification click
        console.log('[PN-SDK-ServiceWorker] Normal notification Click.');
        const notificationData = event.notification.data;
        console.log('[PN-SDK-ServiceWorker] notificationData', notificationData);
        url = notificationData.url;
      } else {
        console.log('[PN-SDK-ServiceWorker] Button Click.', event.action);
        url = event.action;
      }
      console.log('[PN-SDK-ServiceWorker] url', url);

      /**
       * Usually, click tracking is made on our redirect app (r.pn.vg)
       * but for some pushes (i.e. welcome and goodbye push), we track the click here
       */
      let trackData = event.notification.data.track;
      if (trackData) {
        PushnewsServiceWorker.trackClick(trackData).then(() => { });
      }

      const promiseChain = clients.openWindow(url);
      event.waitUntil(promiseChain);
    });

    self.addEventListener('notificationclose', (event) => {
      console.log('[PN-SDK-ServiceWorker] notificationclose', event);
    });

  },

  /**
   * Let the show begin!
   */
  init: function() {
    console.log('[PN-SDK-ServiceWorker] *** init');
    this.processUrlParams();
    this.attachEventListeners();
    console.log('[PN-SDK-ServiceWorker] *** appId', this.appId);
    console.log('[PN-SDK-ServiceWorker] *** baseApiUrl', this.baseApiUrl);
  }

};

PushnewsServiceWorker.init();
